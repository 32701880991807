import { Route, Routes } from 'react-router-dom'
import { MainPage } from './pages/MainPage';
import { AboutPage } from './pages/AboutPage';
import { GamePage } from './pages/GamePage';


function App() {
  return (
    <Routes>
      <Route path='/' element={ <MainPage /> } />
      <Route path='/about' element={ <AboutPage /> } />
      <Route path="/g/:id" element={ <GamePage /> } /> 
    </Routes>
  )
}

export default App;
